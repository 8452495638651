<template>
  <div>
    <div>
      <img src="https://water-1302249616.cos.ap-nanjing.myqcloud.com//2021-12-07/fe73fc6823254d9c8efa77097a0ba204-banner_关于我们.jpg" alt style="width:100%">
    </div>
    <div class="tit-i">
      <img src="../assets/Group.png" alt>
      <h3>联系我们</h3>
      <img src="../assets/Group Copy.png" alt>
      <h5>
        <span>contact us</span>
      </h5>
    </div>
    <div
      style="display:flex;
    margin: 0px auto;
    display: flex;
    width: 1216px;"
    >
      <div class="contact">
        <div id="t-map-container" class="contactleft" style="flex:1;height:500px;margin-right:10px">
          <!-- <img src="../assets/weizhi.png" alt>
          <span>{{ data.detail }}</span> -->
        </div>
        <div class="contactright">
          <el-form ref="form" :rules="rules" :model="form" class="demo-form-inline" :inline="true">
            <el-form-item>
              <el-input v-model="form.name" placeholder="你的姓名" style="margin:0" />
            </el-form-item>
            <el-form-item>
              <el-input v-model="form.contact" placeholder="你的联系方式" style="margin:0" />
            </el-form-item>
            <br>
            <el-form-item>
              <el-input
                v-model="form.others"
                type="textarea"
                style="width:385px"
                :autosize="{ minRows: 4, maxRows: 6}"
              />
            </el-form-item>
          </el-form>
          <el-button type="primary" class="but" @click="handelsubmit()">提交你的需求</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSubmit } from '../api/pc/home'
import { obtaindss } from '@/api/admin/about'
import TcMap from './TMap'
const TMap = new TcMap()
const mapQQ = window.qq
export default {
  data() {
    return {
      data: {},
      form: {
        name: '',
        contact: '',
        others: ''
      },
      rules: {
        name: [
          // { required: true, message: '请输入名称', trigger: 'blur' },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
          { required: true, trigger: 'blur', message: '用户名不能为空' }
        ],
        contact: [
          { required: true, trigger: 'blur', message: '手机号码不能为空' }
        ],
        others: [
          { required: true, trigger: 'blur', message: '备注不能为空' }
        ]

      }

    }
  },
  created() {
    obtaindss().then(res => {
      this.data = res.data.companyAddress
      // this.TMapInstance = TMap.init({ id: 't-map-container', lat: parseFloat(res.data.latitude), lng: parseFloat(res.data.longitude) }, { zoom: 18 })
      setTimeout(() => {
        this.setMarker(new mapQQ.maps.LatLng(28.234677, 112.855919))
      }, 1000)
    })
    // subpage(5)
    //   .then(res => {
    //     console.log('res', res)
    //     // res.data.item.parentDOS[1].subitemEntities.push(res.data.item.parentDOS[1].subitemEntities[0])
    //     this.data = res.data.items[0]
    //     setTimeout(() => {
    //       this.setMarker(new mapQQ.maps.LatLng(28.234677, 112.855919))
    //     }, 1000)
    //   })
    //   .catch(e => {
    //     console.error(e)
    //   })
    // for (let i = 0; i < this.list.length; i++) {
    //   const item = this.list[i];
    //   console.log(item);
    // }
  },
  mounted() {
    this.TMapInstance = TMap.init({ id: 't-map-container', lat: 28.234677, lng: 112.855919 }, { zoom: 18 })
  },
  methods: {
    setMarker(center) {
      if (this.marker) {
        this.marker.setMap(null)
      }
      this.marker = new mapQQ.maps.Marker({
        position: center,
        map: this.TMapInstance
      })
      // 创建InfoWindow实例，并进行初始化
      // this.infowindow = new mapQQ.maps.InfoWindow({
      //   enableCustom: true,
      //   content: "<div class='info_card'>Hello World!</p></div>", // 信息窗口内容
      //   position: center, // 显示信息窗口的坐标
      //   map: this.TMapInstance,
      //   zIndex: 10, // 信息窗的z-index值
      //   offset: { y: -70, x: -5 }
      // })
      // this.infowindow.open()
      this.label = new mapQQ.maps.Label({
        position: center,
        map: this.TMapInstance,
        content: `<div class="info_label" style="margin:5px;line-height:22px;font-size:14px;color:#303133">${this.data.companyAddress.replaceAll('\n', '<br/>')}</div>`
      })
      this.label.setStyle({ border: '0px', borderRadius: '5px', boxShadow: '0 2px 12px 0 rgb(0 0 0 / 10%)' })
    },
    // 提交
    handelsubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          getSubmit(this.form).then(res => {
            console.log('getSubmit', res)
            this.$message({
              message: '提交成功！感谢您的建议',
              type: 'success'
            })
            this.handleClose()
          }).catch(e => {
            this.$message({
              message: '提交失败',
              type: 'danger'
            })
            // this.subLoading = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 清空
    handleClose() {
      this.dialogFormVisible = false
      this.form = {}
    }
  }
}
</script>

<style  lang="scss" scoped>
.tit-i {
  padding: 20px 0;
  text-align: center;
  padding: 50px 0;
  line-height: 36px;
  overflow: hidden;
  h3 {
    font-size: 30px;
    color: #002;
    margin-bottom: 0px;
    display: inline-block;
  }
  h5 {
    font-size: 20px;
    color: #ffffff;
    text-transform: uppercase;
    font-family: Arial;
    margin-top: 10px;
    span {
      color: #e4392a;
    }
  }
  em {
    width: 45px;
    border-bottom: 1px solid #7f7f90;
    display: inline-block;
  }
}
.contact {
  padding-bottom: 164px;
  display: flex;
  flex: 1;
  .contactleft {
    img {
      width: 34px;
    }
  }
  .contactright {
    .but {
      width: 387px;
    }
    margin-left: auto;
  }
}
</style>
<style lang="scss">
.info_card {

}
div>.info-label{
  background:#999
}
</style>
