import request from '../../utils/request'
// 提交logo
export function getSubmit(data = {}) {
  return request({
    url: `/website/logo/save`,
    method: 'post',
    data: data
  })
}

// 提交地址
export function getSubmitads(data = {}) {
  return request({
    url: `/website/companyAddress/save`,
    method: 'post',
    data: data
  })
}
// 提交客服信息
export function getSubmitatt(data = {}) {
  return request({
    url: `/website/contactInformation/save`,
    method: 'post',
    data: data
  })
}

// 提交备案信息
export function getSubmitakeep(data = {}) {
  return request({
    url: `/website/archivalInformation/save`,
    method: 'post',
    data: data
  })
}
// 获取公司地址
export function obtaindss() {
  return request({
    url: `/website/companyAddress/get`,
    method: 'get'
  })
}
// 获取客服信息
export function obtaintt() {
  return request({
    url: `/website/contactInformation/get`,
    method: 'get'
  })
}
// 获取备案信息
export function obtainkeep() {
  return request({
    url: `/website/archivalInformation/get`,
    method: 'get'
  })
}
// 获取首页banner图
export function getbanner() {
  return request({
    url: `/website/banner/getBanner`,
    method: 'get'
  })
}
// 修改banner图提交
export function savebanner(data) {
  return request({
    url: `/website/banner/save`,
    method: 'post',
    data
  })
}
