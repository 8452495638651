/**
 * @author        Lynn
 * @dateTime      2021-05-26
 * @description   TMap Config
 */

// todo 临时解决地图问题
const qq = window.qq
class TMap {
  constructor(qq) {
    this.TMap = null
  }

  init({ id = 'TMap-container', lat = 39.916527, lng = 116.397128 }, opt) {
    const myLatlng = new qq.maps.LatLng(lat, lng)
    const $opt = Object.assign({
      zoom: 12, // 设置地图缩放级别
      center: myLatlng, // 设置中心点样式
      mapTypeId: qq.maps.MapTypeId.LANDFORM // 设置地图样式详情参见MapType
    }, opt)

    this.TMap = new qq.maps.Map(document.getElementById(id), $opt)

    return this.TMap
  }

  geocoder(address) {
    return new Promise((resolve, reject) => {
      // 这里的 "jsonCallBack" ，和调用的 jsonp 的 url 中的 callback 值相对应（见粗体字）
      window.jsonCallBack = (result) => {
        console.log('result', result)
        resolve(result)
      }
      const JSONP = document.createElement('script')

      JSONP.type = 'text/javascript'

      JSONP.src = `https://apis.map.qq.com/ws/geocoder/v1?key=QVLBZ-YUULR-OUMW7-WKXFD-4SUWS-UCBIH&address=${address}output=JSONP`

      document.getElementsByTagName('head')[0].appendChild(JSONP)

      setTimeout(() => {
        document.getElementsByTagName('head')[0].removeChild(JSONP)
      }, 500)
    })
  }

  setCenter(lat, lng) {
    this.TMap.setCenter(new qq.maps.LatLng(lat, lng))
  }
}

export default TMap
